import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Container, Divider, Flex, Grid, GridItem, Image, Link, ListItem, UnorderedList } from "@chakra-ui/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { publicConfig } from "../config.public";
const firstBasicLink = {
  mt: {
    base: 3,
    lg: 0
  },
  display: {
    base: "inherit",
    lg: "inline-block"
  },
  borderRight: {
    base: "none",
    lg: "1px solid"
  },
  borderRightColor: {
    base: "none",
    lg: "grey.300"
  },
  paddingRight: {
    base: 0,
    lg: 4
  }
};
const basicLink = {
  ...firstBasicLink,
  paddingLeft: {
    base: 0,
    lg: 4
  }
};
const lastLink = {
  display: {
    base: "inherit",
    lg: "inline-block"
  },
  mt: {
    base: 3,
    lg: 3
  },
  // paddingRight: { base: 0, lg: 4 },
  paddingLeft: {
    base: 0,
    lg: 4
  }
};
const Footer = () => {
  const {
    pathname
  } = useRouter();
  const [ressource, setRessource] = useState<string>();
  useEffect(() => {
    if (pathname === "/acces-recruteur") {
      setRessource("recruter");
    }
    if (pathname === "/organisme-de-formation") {
      setRessource("cfa");
    }
  }, []);
  return <Box as="footer" borderTop="1px solid" borderTopColor="info" paddingTop={6} marginTop={12} data-sentry-element="Box" data-sentry-component="Footer" data-sentry-source-file="footer.tsx">
      <Container variant={"responsiveContainer"} data-sentry-element="Container" data-sentry-source-file="footer.tsx">
        <Grid templateColumns={{
        base: "1fr",
        lg: "repeat(4, 1fr)"
      }} data-sentry-element="Grid" data-sentry-source-file="footer.tsx">
          <GridItem data-sentry-element="GridItem" data-sentry-source-file="footer.tsx">
            <Image src="/images/marianne.svg" aria-hidden={true} alt="" width="134" height="122" data-sentry-element="Image" data-sentry-source-file="footer.tsx" />
          </GridItem>
          <GridItem display="flex" height="100%" alignItems="center" data-sentry-element="GridItem" data-sentry-source-file="footer.tsx">
            <Image src="/images/france_relance.svg" alt="" width="81" height="81" data-sentry-element="Image" data-sentry-source-file="footer.tsx" />
          </GridItem>
          <GridItem colSpan={{
          base: 1,
          lg: 2
        }} data-sentry-element="GridItem" data-sentry-source-file="footer.tsx">
            <Box pl={{
            base: 0,
            lg: 4
          }} mt={{
            base: 8,
            lg: 0
          }} data-sentry-element="Box" data-sentry-source-file="footer.tsx">
              <Box fontSize="14px" color="grey.800" data-sentry-element="Box" data-sentry-source-file="footer.tsx">
                La bonne alternance. Trouvez votre alternance.
              </Box>
              <Box fontSize="14px" color="grey.800" mt="6" data-sentry-element="Box" data-sentry-source-file="footer.tsx">
                La bonne alternance est proposée par les services suivants :
              </Box>
              <Flex flexDirection={{
              base: "column",
              lg: "row"
            }} mt="6" data-sentry-element="Flex" data-sentry-source-file="footer.tsx">
                <Link href="https://www.francetravail.fr" aria-label="Accès au site de France Travail - nouvelle fenêtre" isExternal fontSize={14} fontWeight={700} color="grey.425" mr={4} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                  francetravail.fr <ExternalLinkIcon mx="2px" data-sentry-element="ExternalLinkIcon" data-sentry-source-file="footer.tsx" />
                </Link>
                <Link href="https://gouvernement.fr" aria-label="Accès au site gouvernement.fr - nouvelle fenêtre" isExternal fontSize={14} fontWeight={700} color="grey.425" mr={4} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                  gouvernement.fr <ExternalLinkIcon mx="2px" data-sentry-element="ExternalLinkIcon" data-sentry-source-file="footer.tsx" />
                </Link>
                <Link href="https://service-public.fr" aria-label="Accès au site service-public.fr - nouvelle fenêtre" isExternal fontSize={14} fontWeight={700} color="grey.425" mr={4} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                  service-public.fr <ExternalLinkIcon mx="2px" data-sentry-element="ExternalLinkIcon" data-sentry-source-file="footer.tsx" />
                </Link>
                <Link href="https://data.gouv.fr" aria-label="Accès au site data.gouv - nouvelle fenêtre" isExternal fontSize={14} fontWeight={700} color="grey.425" mr={4} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                  data.gouv.fr <ExternalLinkIcon mx="2px" data-sentry-element="ExternalLinkIcon" data-sentry-source-file="footer.tsx" />
                </Link>
              </Flex>
            </Box>
          </GridItem>
        </Grid>

        <Divider mt={6} mb={3} data-sentry-element="Divider" data-sentry-source-file="footer.tsx"></Divider>

        <Grid data-sentry-element="Grid" data-sentry-source-file="footer.tsx">
          <GridItem data-sentry-element="GridItem" data-sentry-source-file="footer.tsx">
            <UnorderedList listStyleType="none" color="grey.425" fontSize={12} m={0} data-sentry-element="UnorderedList" data-sentry-source-file="footer.tsx">
              <ListItem {...firstBasicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="/mentions-legales" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès aux mentions légales" data-sentry-element="Link" data-sentry-source-file="footer.tsx">Mentions légales</Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="/cgu" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès aux conditions générales d'utilisation" data-sentry-element="Link" data-sentry-source-file="footer.tsx">CGU</Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="/politique-de-confidentialite" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès à la politique de confidentialité" data-sentry-element="Link" data-sentry-source-file="footer.tsx">Politique de confidentialité</Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="/stats" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès aux statistiques du service" data-sentry-element="Link" data-sentry-source-file="footer.tsx">Statistiques</Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="/faq" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès à la foire aux questions" data-sentry-element="Link" data-sentry-source-file="footer.tsx">FAQ</Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href={{
                pathname: "/ressources",
                hash: ressource
              }} data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès à la page Ressources" data-sentry-element="Link" data-sentry-source-file="footer.tsx">Ressources</Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="https://labonnealternance.sites.beta.gouv.fr?utm_source=lba&utm_medium=website&utm_campaign=lba_footer" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link isExternal aria-label="Accès au blog de La bonne alternance" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                    Blog
                  </Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="/contact" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès à la page Contact" data-sentry-element="Link" data-sentry-source-file="footer.tsx">Contact</Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="/metiers" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès à la page Métiers" data-sentry-element="Link" data-sentry-source-file="footer.tsx">Métiers</Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="/a-propos" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès à la page A propos" data-sentry-element="Link" data-sentry-source-file="footer.tsx">A propos</Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref target="_blank" href="https://github.com/mission-apprentissage/labonnealternance" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Notre code source" isExternal data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                    Code source
                    <ExternalLinkIcon mx="2px" data-sentry-element="ExternalLinkIcon" data-sentry-source-file="footer.tsx" />
                  </Link>
                </NextLink>
              </ListItem>
              <ListItem {...basicLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="/espace-developpeurs" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès à l'espace développeurs" data-sentry-element="Link" data-sentry-source-file="footer.tsx">Espace développeurs</Link>
                </NextLink>
              </ListItem>
              <ListItem {...lastLink} data-sentry-element="ListItem" data-sentry-source-file="footer.tsx">
                <NextLink legacyBehavior passHref href="/accessibilite" data-sentry-element="NextLink" data-sentry-source-file="footer.tsx">
                  <Link aria-label="Accès à la déclaration d'accessibilité" data-sentry-element="Link" data-sentry-source-file="footer.tsx">Accessibilité: non conforme</Link>
                </NextLink>
              </ListItem>
            </UnorderedList>
          </GridItem>
          <GridItem data-sentry-element="GridItem" data-sentry-source-file="footer.tsx">
            <Box color="grey.425" fontSize={12} mt={6} data-sentry-element="Box" data-sentry-source-file="footer.tsx">
              Sauf mention contraire, tous les contenus de ce site sont sous licence{" "}
              <Link href="https://www.etalab.gouv.fr/licence-version-2-0-de-la-licence-ouverte-suite-a-la-consultation-et-presentation-du-decret" aria-label="Accès au site Etalab - nouvelle fenêtre" textDecoration="underline" isExternal mr={4} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                etalab-2.0 <ExternalLinkIcon mx="2px" data-sentry-element="ExternalLinkIcon" data-sentry-source-file="footer.tsx" />
              </Link>
            </Box>
            <Box color="grey.425" fontSize={12} mt={6} pb={12} data-sentry-element="Box" data-sentry-source-file="footer.tsx">
              v.{publicConfig.version} © République française {new Date().getFullYear()}
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Box>;
};
export default Footer;